<template>
  <div>
    <StatusFilterList
      v-bind="params"
      v-model="params.status"
      @change="change"
      :list="list"
    >
      <div class="text-right" slot="opt">
        <a-space :size="20">
          <router-link to="/evaluate/evaluate-policy-create">
            <a-button type="primary">新建政策</a-button>
          </router-link>
          
          <gf-re-input-search
            @search="change({ name: $event, current: 1 })"
            style="width: 240px"
          >
          </gf-re-input-search>
        </a-space>
      </div>
      <ProCard
        slot="card"
        slot-scope="{ dataSource }"
        :showDel="true"
        @del="change"
        :dataSource="dataSource"
        @click.native="push(dataSource.ID)"
      >
        <status-box
          :status="dataSource.status"
          :evaluate="dataSource.evaluate"
          :ReviewCount="dataSource.ReviewCount"
          slot="status"
        >
          <span v-if="dataSource.status === 30 && dataSource.evaluate === 0"
            >匹配中</span
          >
          <span
            v-else-if="dataSource.status === 30 && dataSource.evaluate === 1"
          >
            评估中
          </span>
          <span
            v-else-if="
              dataSource.status === 30 &&
              dataSource.evaluate === 2 &&
              !dataSource.ReviewCount
            "
          >
            已完成
          </span>
          <span
            v-else-if="
              dataSource.status === 30 &&
              dataSource.evaluate === 2 &&
              dataSource.ReviewCount
            "
            >已评价</span
          >
          <span v-else-if="dataSource.status === -1">已关闭</span>
        </status-box>
      </ProCard>
    </StatusFilterList>
  </div>
</template>

<script>
import ProCard from "./components/card.vue";

import { getMyPolicyList } from "@/api/api/policy";

export default {
  components: { ProCard },
  data() {
    return {
      params: {
        current: 1,
        limit: 5,
        total: 0,
        status: 0,
        name: "",
      },
      list: [],
    };
  },
  computed: {
    role() {
      return this.$store.state.userInfo.role;
    },
  },
  created() {
    this.change();
  },
  methods: {
    change(e) {
      const params = this.generateParams(e);
      this.getList(params);
    },
    generateParams(e) {
      Object.assign(this.params, e);

      const { name, limit, current } = this.params;

      const temp = {
        limit,
        offset: limit * (current - 1),
        name,
      };

      return temp;
    },
    getList(params) {
      getMyPolicyList(params)
        .then(({ count, rows }) => {
          this.list = rows;
          this.$set(this.params, "total", count);
          let current = this.params.current;
          if (rows.length === 0 && current !== 1) {
            console.log(current, "当前页面没有数据了");
          }
        })
        .catch(() => {
          this.$message.error("获取列表失败");
        });
    },
    push(id) {
      this.$router.push(`/evaluate/evaluate-policy-apply/${id}`);
      this.id = id;
    },
  },
};
</script>